import { ReactElement } from "react";
import { Anchor } from "@twilio-paste/core/anchor";

const protocol = "((?:http|https|rtsp):\\/\\/)";
const domainAndSubdomains = "(?:(?:[a-zA-Z0-9][-]*)*[a-zA-Z0-9]\\.)+";
const genericTopLevelDomains =
    "aero|arpa|asia|app|" +
    "biz|buzz|" +
    "cat|com|coop|credit|" +
    "edu|" +
    "gov|" +
    "info|int|" +
    "jobs|" +
    "mil|mobi|museum|" +
    "name|net|" +
    "org|" +
    "pro|" +
    "tel|travel";
const countryTopLevelDomains =
    "a[cdefgilmnoqrstuwxz]|" +
    "b[abdefghijlmnoqrstvwyz]|" +
    "c[acdfghiklmnoruvwxyz]|" +
    "d[ejkmoz]|" +
    "e[ceghrstu]|" +
    "f[ijkmor]|" +
    "g[abdefghilmnpqrstuwy]|" +
    "h[kmnrtu]|" +
    "i[delmnoqrst]|" +
    "j[emop]|" +
    "k[eghimnprwyz]|" +
    "l[abcikrstuvy]|" +
    "m[acdefghklmnopqrstuvwxyz]|" +
    "n[acefgilopruz]|" +
    "om|" +
    "p[aefghklmnrstwy]|" +
    "qa|" +
    "r[eosuw]|" +
    "s[abcdeghijklmnorstuvxyz]|" +
    "t[cdfghjklmnoprtvwz]|" +
    "u[agkmsyz]|" +
    "v[aceginu]|" +
    "w[fs]|" +
    "y[etu]|" +
    "z[amw]";
const port = "(?:\\:\\d{1,5})?";
const path = '(?:[/?#][^\\s"]*)?';
const startOfMatchedUrl = "(?:\\b|^)";
const endOfMatchedUrl = "(?:\\b|$)";

// only captured group is the protocol, e.g. https://
const urlRegexString = `${startOfMatchedUrl}(?:${protocol}\\S+|(?:${protocol})?${domainAndSubdomains}(?:${genericTopLevelDomains}|${countryTopLevelDomains})${port}${path})${endOfMatchedUrl}`;
// const urlRegex = new RegExp(urlRegexString, "gi");

const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/g;
const phoneRegex = /\b\d{10}\b|\b\d{3}[-.\s]?\d{3}[-.\s]?\d{4}\b|\(\d{3}\)\s*\d{3}[-.\s]?\d{4}\b/g;

const combinedRegex = new RegExp(`${urlRegexString}|${emailRegex.source}|${phoneRegex.source}`, "gi");

export const parseMessageBody = (body: string, belongToCurrentUser: boolean) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    belongToCurrentUser = belongToCurrentUser || false;

    // Step 1: Truncate the message body to remove ||System|| and everything after it
    const systemTagIndex = body.indexOf("||System||");
    if (systemTagIndex !== -1) {
        body = body.substring(0, systemTagIndex);
    }

    const renderedResult: Array<ReactElement | string> = [];
    const matchedEntities: Set<string> = new Set();

    const lineBreakRegexp = new RegExp(/[\n\r]/);
    const lines = body.split(lineBreakRegexp);
    lines.forEach((line, lineIndex) => {
        let index = 0;
        let regexResult;

        while ((regexResult = combinedRegex.exec(line))) {
            const prevText = line.substring(index, regexResult.index);
            if (prevText) {
                renderedResult.push(prevText);
            }

            const matchedText = regexResult[0];

            // Avoid duplication by checking if this entity has already been matched
            if (matchedEntities.has(matchedText)) {
                index = regexResult.index + regexResult[0].length;
                continue;
            }
            matchedEntities.add(matchedText);

            let href: string;
            if (emailRegex.test(matchedText)) {
                href = `mailto:${matchedText}`;
            } else if (phoneRegex.test(matchedText)) {
                href = `tel:${matchedText.replace(/\D/g, "")}`; // Ensure only digits for tel: URL
            } else {
                const [, urlProtocol] = regexResult;
                href = urlProtocol ? matchedText : `http://${matchedText}`;
            }

            renderedResult.push(
                <Anchor href={href} key={`${lineIndex}-${index}`} variant="default">
                    {matchedText}
                </Anchor>
            );

            index = regexResult.index + regexResult[0].length;
        }

        const remainingText = line.substring(index, line.length);
        if (remainingText) {
            renderedResult.push(remainingText);
        }

        renderedResult.push("\n");
    });

    renderedResult.pop(); // Remove the last unnecessary newline
    return renderedResult;
};