import { BoxStyleProps } from "@twilio-paste/core/box";

export const containerStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "space30",
    paddingTop: "space20",
    paddingBottom: "space20",
    borderTopLeftRadius: "borderRadius20",
    borderTopRightRadius: "borderRadius20"
};
