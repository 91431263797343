/* eslint-disable react/jsx-max-depth */

import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

export function RootContainer() {
    const { currentPhase } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase
        // expanded: session.expanded
    }));

    return (
        <Box
            data-test="root-container"
            style={{
                backgroundColor: "#f8f8fa",
                height: "100dvh"
            }}
        >
            <div
                style={{
                    borderBottom: "1px solid rgb(229, 231, 235)",
                    padding: "10px 0 13px",
                    backgroundColor: "#fff"
                }}
            >
                <img
                    src="https://uploads-ssl.webflow.com/62c343ebaaa78711d8fa324e/62c34f60a9aa4b8300fc4565_janover-logo.svg"
                    alt=""
                    style={{ width: "120px", height: "auto", margin: "0 auto", display: "block" }}
                />
            </div>
            <div
                style={{
                    maxWidth: "600px",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    height: "calc(100% - 60px)"
                }}
            >
                {getPhaseComponent(currentPhase)}
            </div>
        </Box>
    );
}
