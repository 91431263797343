import { Box } from "@twilio-paste/core/box";

import { containerStyles } from "./styles/Header.styles";

declare global {
    interface Window {
        trackEvent: (event: string, data?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

export const Header = () => {
    return (
        <Box
            as="header"
            {...containerStyles}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
            {/* <h1
                style={{
                    fontSize: "2.4rem",
                    fontWeight: 600,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundImage: "linear-gradient(180deg, #000, #777)",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "1.5",
                    marginBottom: "10px"
                }}
            >
                The future of finance is here
            </h1> */}
        </Box>
    );
};
