/* eslint-disable react/jsx-max-depth */
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent, useEffect, useRef } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { InputBox } from "@twilio-paste/core";
import { SendIcon } from "@twilio-paste/icons/esm/SendIcon";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { messageListStyles } from "./styles/MessageList.styles";
import {
    innerInputStyles,
    messageOptionContainerStyles,
    textAreaContainerStyles,
    formStyles
} from "./styles/MessageInput.styles";
import { CHAR_LIMIT } from "../constants";
import { ChatPing } from "./ChatPing";

declare global {
    interface Window {
        trackEvent: (event: string, data?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

export const PreEngagementFormPhase = () => {
    const { name, email, query } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const { sessionData } = useSelector((state: AppState) => state.config) || {};
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        textAreaRef.current?.setAttribute("rows", "1");
        textAreaRef.current?.focus();
    }, [textAreaRef]);

    const handleSubmit = async (e: FormEvent | CustomEvent, receivedMessage?: string) => {
        if ("preventDefault" in e) {
            e.preventDefault();
        }
        const messageToSend = receivedMessage || query; // Use received message first
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name || "You",
                    email,
                    query: messageToSend,
                    ...sessionData
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
            if (window.trackEvent) {
                window.trackEvent("Chat - Session Initiated", { mode: "standalone" });
            }
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    useEffect(() => {
        const handleCustomMessageEvent = (event: CustomEvent) => {
            const { message } = event.detail;
            if (message) {
                handleSubmit(new CustomEvent("submit"), message);
            }
        };
        document.addEventListener("sendInitialMessage", handleCustomMessageEvent as EventListener);
        return () => {
            document.removeEventListener("sendInitialMessage", handleCustomMessageEvent as EventListener);
        };
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const event = new CustomEvent("preengageformLoaded");
        document.dispatchEvent(event);
    }, []);
    
    return (
        <>
            <Header />
            <NotificationBar />
            <Box {...messageListStyles}>
                <Box aria-label="Chat messages" role="log" aria-relevant="additions">
                    <Box
                        style={{
                            gap: "10px",
                            padding: "0 25px 0 30px"
                        }}
                    >
                        {/* <div
                            style={{
                                border: "1px solid rgb(229, 231, 235)",
                                padding: "20px 20px",
                                borderRadius: "5px",
                                background: "#fff",
                                width: "100%"
                            }}
                        >
                             <div>
                                <div style={{ fontSize: "1.2rem", fontWeight: 600, margin: "0 0 0.5rem" }}>
                                    Welcome to Janover AI Chat!
                                </div>
                                <div style={{ fontSize: "0.9rem", lineHeight: "1.5", marginTop: "5px" }}>
                                    Learn about commercial real estate and business financing, get answers to all of
                                    your questions, and find out what the best options on the market are for your
                                    business.
                                </div>
                            </div> */}
                        {/* <div>
                                <div style={{ fontSize: "1rem", fontWeight: 600 }}>Apply for a Quote</div>
                                <div style={{ fontSize: "0.7rem", lineHeight: "1.5", marginTop: "5px" }}>
                                    Get a quote for your financing needs in minutes
                                </div>
                            </div> 
                        </div>*/}
                    </Box>
                    <br />
                    <ChatPing />
                </Box>
            </Box>
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <InputBox element="MESSAGE_INPUT_BOX">
                    <Box as="div" {...innerInputStyles}>
                        <Box {...textAreaContainerStyles}>
                            <TextArea
                                ref={textAreaRef}
                                data-test="message-input-textarea"
                                placeholder="Type your message"
                                value={query}
                                name="query"
                                element="MESSAGE_INPUT"
                                onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                                onKeyPress={handleKeyPress}
                                required
                                maxLength={CHAR_LIMIT}
                            />
                        </Box>
                        <Box {...messageOptionContainerStyles}>
                            <Button
                                data-test="message-send-button"
                                variant="primary_icon"
                                size="icon_small"
                                type="submit"
                            >
                                <SendIcon decorative={false} title="Send message" size="sizeIcon30" />
                            </Button>
                        </Box>
                    </Box>
                </InputBox>
            </Box>
        </>
    );
};
