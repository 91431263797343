import { Box, Text, Avatar } from "@twilio-paste/core";

import {
    getInnerContainerStyles,
    authorStyles,
    bodyStyles,
    outerContainerStyles,
    bubbleAndAvatarContainerStyles,
    getAvatarContainerStyles
} from "./styles/MessageBubble.styles";

export const ChatPing = () => {
    const promptAuthor = "Michael";
    const promptBody =
        "👋 Hi there! Can I help you get started with a new loan quote? Or do you have any questions I can answer first?";

    return (
        <Box {...outerContainerStyles} data-prompt-bubble>
            <Box {...bubbleAndAvatarContainerStyles}>
                <Box
                    {...getAvatarContainerStyles(false)}
                    overflow="auto"
                    borderRadius="borderRadius0"
                    data-testid="avatar-container"
                >
                    <Box overflow="auto" position="relative">
                        <Avatar
                            size="sizeIcon40"
                            name="Michael"
                            src="https://periwinkle-wallaby-9607.twil.io/assets/michael1b.jpeg"
                        />
                        <Box
                            position="absolute"
                            bottom="0"
                            right="0"
                            backgroundColor="colorBackgroundAvailable"
                            width="8px"
                            height="8px"
                            borderRadius="borderRadiusCircle"
                        />
                    </Box>
                </Box>
                <Box
                    {...getInnerContainerStyles(false)}
                    style={{ background: "#fff", borderRadius: "5px", border: "1px solid rgb(229, 231, 235)" }}
                >
                    <Text {...authorStyles} as="p" title={promptAuthor}>
                        {promptAuthor}
                    </Text>
                    <Text as="p" {...bodyStyles} style={{ color: "#000", lineHeight: "1.5", fontWeight: 400 }}>
                        {promptBody}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
